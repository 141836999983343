import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Logo from "../logo/Logo";
import classes from "./Footer.module.scss";

import Contact from "../Contact/Contact";
const Footer = () => {
  return (
    <footer className="pt-8 bg-secondary text-white footer">
      <Container>
        <Row>
          <Col sm={6}>
            <Logo />
            <p className={`${classes["footer__about"]} mt-4`}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clit sanctus est Lorem ipsum dolor sit
            </p>
          </Col>
          <Col sm={3}>
            <h3 className="text-primary fw-bold mb-4">Information</h3>
            <ul>
              <li className="mb-3">
                <NavLink className="text-white" to="/order">
                  Order
                </NavLink>
              </li>
              <li className="mb-3">
                <NavLink className="text-white" to="/order">
                  Delivery
                </NavLink>
              </li>
              <li className="mb-3">
                <NavLink className="text-white" to="/order">
                  Contact
                </NavLink>
              </li>
            </ul>
          </Col>
          <Col sm={3}>
            <h3 className="text-primary fw-bold mb-4">Contact</h3>
              <Contact/>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

import Item from "../Item/Item";
import SectionHeading from "../SectionHeading/SectionHeading";
import { Col, Container, Row,Button } from "react-bootstrap";

import {Product} from '@/types/commonTypes'



interface ItemsProps {
    items:Product[],
    heading:string
}

const Items = ({items,heading}:ItemsProps) => {
    const itemsJsx = items.map((item) => (
      <Col xl={3} lg={4} sm={6} className="mb-5 mb-xl-3" key={item.id}>
        <Item itemStyles={{maxWidth:'300px'}} item={item}></Item>
      </Col>
    ));
    return (
      <section className="section-margin">
        <SectionHeading heading={heading} />
        <Container className="mb-6">
          <Row>{itemsJsx}</Row>          
        </Container>
        <Button  className="btn-custom-outline-primary btn-custom-outline sm fw-bold d-block mx-auto">
          <span>View All Items</span>
        </Button>
      </section>
    );
  };

  export default Items
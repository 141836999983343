import { NavLink } from "react-router-dom";
import classes from "./Logo.module.scss";
import logoImg from "@/assets/images/logo.png";
const Logo = () => {
  return (
    <NavLink
      to="/"
      className="d-flex flex-column text-white text-uppercase"
    >
      <img className={`img-fluid ${classes.logo}`} src={logoImg} alt="logo" />
    </NavLink>
  );
};

export default Logo;

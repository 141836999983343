import { RouteInterface } from "../types/routeInterface";

// import authRoutes from './authRoutes';
import MainLayout from "../layouts/MainLayout";

import Home from "../pages/home/Home";
import React from "react";

const OrderLayout = React.lazy(() => import("@/layouts/OrderLayout"));

const AboutUs = React.lazy(() => import("@/pages/aboutUs/AboutUs"));
const Products = React.lazy(() => import("@/pages/products/Products"));
const Faqs = React.lazy(() => import("@/pages/faqs/Faqs"));
const Contact = React.lazy(() => import("@/pages/contact/Contact"));
const Cart = React.lazy(() => import("@/pages/cart/Cart"));
const Checkout = React.lazy(() => import("@/pages/checkout/Checkout"));
const OrderSuccess = React.lazy(
  () => import("@/pages/orderSuccess/orderSuccess")
);

const routes: RouteInterface[] = [
  {
    path: "/",
    component: Home,
    layout: MainLayout,
  },
  {
    path: "/about",
    component: AboutUs,
    layout: MainLayout,
  },
  {
    path: "/products",
    component: Products,
    layout: MainLayout,
  },
  {
    path: "/faqs",
    component: Faqs,
    layout: MainLayout,
  },
  {
    path: "/contact",
    component: Contact,
    layout: MainLayout,
  },
  {
    path: "/cart",
    component: Cart,
    layout: MainLayout,
  },
  {
    path: "/checkout",
    component: Checkout,
    layout: MainLayout,
  },
  {
    path: "/order/success",
    component: OrderSuccess,
    layout: OrderLayout,
  },

  //   ...authRoutes,
];

export default routes;

import Items from "../../general/Items/Items";

import { useGetProductsQuery } from "@/store/services/Products";
import { Spinner } from "react-bootstrap";
import Error from "@/components/general/Error/Error";
const FoodSpecials = () => {
  const { data: items, isLoading, error } = useGetProductsQuery();

  let itemsJsx = null;

  if (isLoading)
    itemsJsx = (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  if (error) {
    itemsJsx = <Error err="Failed to fetch specials"></Error>;
  }
  if (!isLoading && items?.items.length) {
    itemsJsx = <Items items={items?.items} heading="Lamak Foods Special" />;
  }
  return itemsJsx;
};

export default FoodSpecials;

import { Container } from "react-bootstrap";

import { Swiper, SwiperSlide} from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";


import SwiperCore,{ Autoplay } from "swiper";

import SectionHeading from "../../general/SectionHeading/SectionHeading";

import Rating from "react-rating";

import { FaStar, FaRegStar } from "react-icons/fa";

import classes from "./Reviews.module.scss";

SwiperCore.use([Autoplay]);

const reviews = [
  {
    customerName: "Customer 1",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea",
    rating: 5,
  },
  {
    customerName: "Customer 2",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
    rating: 4.5,
  },
  {
    customerName: "Customer 3",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt",
    rating: 3.3,
  },
];

const Reviews = () => {
  return (
    <section className="section-margin reviews">
      <SectionHeading heading="Our Customer's Reviews" />
      <Container>
        <Swiper spaceBetween={50} slidesPerView={1} autoplay={{ delay: 4000 }}>
          {reviews.map((review) => (
            <SwiperSlide className="text-center">
              <h5 className={`fw-bold ${classes["reviews__customer"]} mb-5`}>
                {review.customerName}
              </h5>
              <p className={`${classes["reviews__text"]} mb-4`}>
                {review.text}
              </p>
              <Rating
                initialRating={review.rating}
                readonly
                fullSymbol={<FaStar className={`text-primary ${classes['reviews__icon']}`} size={35} />}
                emptySymbol={<FaRegStar size={35} className={`${classes['reviews__icon']}`} />}
                fractions={10}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default Reviews;

import FoodSpecials from "../../components/home/FoodSpecials/FoodSpecials";
import OrderProcess from "../../components/home/OrderProcess/OrderProcess";
import Reviews from "../../components/home/Reviews/Reviews";
import TopSelling from "../../components/home/TopSelling/TopSelling";
import WhyLamak from "../../components/home/WhyLamak/WhyLamak";

const Home = () => {
  return (
    <>
      <FoodSpecials />
      <WhyLamak />
      {/* <TopSelling /> */}
      <OrderProcess />
      <Reviews/>
    </>
  );
};

export default Home;

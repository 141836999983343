import { useEffect, useState } from "react";



import { NavLink } from "react-router-dom";

import classes from "./Header.module.scss";

import { Container } from "react-bootstrap";
import Logo from "../../general/logo/Logo";
import Cart from "../../general/Cart/Cart";

import { FaHamburger } from "react-icons/fa";


import Sidebar from "@/components/general/sidebar/Sidebar";

interface Link {
  text: string;
  to: string;
  heroImg?: string;
  heading?: [string, string];
}

const defaultHeaderLinks: Link[] = [
  {
    text: "Home",
    to: "/",
    heroImg: require("../../../assets/images/home/hero-header.jpg").default,
    heading: ["Lamak", "Foods"],
  },
  {
    text: "Products",
    to: "/products",
    heroImg: require("../../../assets/images/home/hero-header.jpg").default,
    heading: ["Products", ""],
  },
  // {
  //   text: "Track",
  //   to: "/track",
  //   heading: ["About", "Us"],
  // },

  {
    text: "FAQs",
    to: "/faqs",
    heading: ["FAQs", ""],
  },
  {
    text: "Contact",
    to: "/contact",
    heading: ["Contact", "Us"],
    heroImg: require("../../../assets/images/about-us/hero-header.jpg").default,
  },
  {
    text: "About",
    to: "/about",
    heading: ["About", "Us"],
    heroImg: require("../../../assets/images/about-us/hero-header.jpg").default,
  },
];

const Header = () => {
 

  const [headerLinks, setHeaderLinks] = useState<null | Link[]>(null);

  useEffect(() => {
    setHeaderLinks([...defaultHeaderLinks]);
  }, []);

  const linksJsx =
    headerLinks &&
    headerLinks.map((link) => (
      <li
        className={
          "mx-3 px-2 d-flex align-items-center position-relative mb-4 mb-lg-0 " +
          classes.header__navitem
        }
        key={link.text}
      >
        <NavLink
          className={(navData) =>
            (navData.isActive ? classes.active : "") +
            " fw-bold text-center " +
            classes.header__navlink
          }
          to={link.to}
        >
          {link.text}
        </NavLink>
      </li>
    ));
  const [showSb, setShowSb] = useState(false);
  return (
    <>
      <header className={classes.header + " text-white position-sticky w-100 py-3 py-sm-0"}>
        <Container>
          <div className="d-flex">
            <Logo />
            <nav className="mx-auto d-lg-flex d-none">
              <ul className="d-flex mb-0 text-center">{linksJsx}</ul>
            </nav>
            <Sidebar show={showSb} handleClose={() => setShowSb(false)}>
              <ul className="mb-0 text-center">{linksJsx}</ul>
            </Sidebar>
            <div className="d-flex align-items-center ms-auto ms-lg-0">
              <Cart />
              <FaHamburger
                className={`cursor-pointer d-block d-lg-none ms-4 ${classes['header__hamburger']}`}
                onClick={() => setShowSb(true)}
                size={40}
              />
            </div>
          </div>
        </Container>
      </header>

    </>
  );
};

export default Header;

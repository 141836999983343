import { Offcanvas } from "react-bootstrap";
import ReactDOM from "react-dom";

interface Sidebar {
  show: Boolean;
  children: React.ReactNode;
  handleClose: (show: boolean) => void;
}

const SB = ({ children, show, handleClose }: Sidebar) => {
  return ReactDOM.createPortal(
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>,
    document.body
  );
};

export default SB;

import React from "react";

import { FaPlus, FaMinus } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";

enum Counter {
  Inc,
  Dec,
}

const ItemCounter = ({
  initialCount = 0,
  size = 15,
  setCountExternal,
}: {
  size?: number;
  initialCount?: number;
  setCountExternal: (count: number, type: Counter) => void;
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const _setCount = (type: Counter) => {
    if (type === Counter.Dec) {
      setCount((prevCount) => {
        const newCount = prevCount - 1 > 0 ? prevCount - 1 : prevCount;
        return newCount;
      });
      const newCount = count;
      if (newCount > 1) setCountExternal(newCount - 1, Counter.Dec);
      return;
    }
    setCount((prevCount) => {
      const newCount = prevCount + 1;

      return newCount;
    });
    setCountExternal(count + 1, Counter.Inc);
  };
  return (
    <Button
      variant="secondary"
      className="d-flex justify-content-center fw-bold py-3 align-items-center"
    >
      <FaMinus size={size} onClick={() => _setCount(Counter.Dec)} />
      <span className="mx-2">{count}</span>
      <FaPlus size={size} onClick={() => _setCount(Counter.Inc)} />
    </Button>
  );
};

export default ItemCounter;

import Footer from "../components/general/Footer/Footer";
import Header from "../components/navigation/header/Header";

import HeroSection from "@/components/general/HeroSection/HeroSection";
import { useLocation } from "react-router-dom";
interface Link {
  to: string;
  heroImg?: string;
  heading?: [string, string];
}


const defaultHeaderLinks: Link[] = [
  {

    to: "/",
    heroImg: require("@/assets/images/home/hero-header.jpg").default,
    heading: ["Lamak", "Foods"],
  },
  {
    to: "/products",
    heroImg: require("@/assets/images/home/hero-header.jpg").default,
    heading: ["Products", ""],
  },
  // {
  //   to: "/track",
  //   heading: ["About", "Us"],
  // },

  {
 
    to: "/faqs",
    heading: ["FAQs", ""],
  },
  {

    to: "/contact",
    heading: ["Contact", "Us"],
    heroImg: require("@/assets/images/about-us/hero-header.jpg").default,
  },
  {
    to: "/about",
    heading: ["About", "Us"],
    heroImg: require("@/assets/images/about-us/hero-header.jpg").default,
  },
];

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();


  let requiredLink = defaultHeaderLinks?.find((link) => link.to === location.pathname);
  
  if (!requiredLink) {
    if (location.pathname === "/cart") {
      requiredLink = {
        heading: ["Shopping", "Cart"],
        heroImg: require("@/assets/images/about-us/hero-header.jpg")
          .default,
        to: "/cart",
      };
    }
    if (location.pathname === "/checkout") {
      requiredLink = {
        heading: ["Checkout", ""],
        heroImg: require("@/assets/images/about-us/hero-header.jpg")
          .default,
        to: "/checkout",
      };
    }
  }



  return (
    <div className="main-layout">
      <Header />
      <HeroSection
        heading={requiredLink?.heading ?? ["Lamak", "Foods"]}
        img={
          requiredLink?.heroImg ??
          require("@/assets/images/home/hero-header.jpg").default
        }
      />
      <main className="position-relative main-layout__content pt-3">
        {children}
      </main>
      <Footer/>
    </div>
  );
};

export default MainLayout;

import { Product } from "@/types/commonTypes";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBase } from "./Base";



export const productApi = apiBase("products").injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<{ items: Product[]; count: number,offset:number }, {page:number,limit:number} | void>({
      query: (args) => `products?page=${args?.page}&limit=${args?.limit}`,
    }),
  }),
});

// export const productApi = createApi({
//   reducerPath: "products",
//   baseQuery: fetchBaseQuery({
//     baseUrl: "http://1754-182-182-103-9.ngrok.io/",
//     prepareHeaders: (headers, { getState }) => {
//       headers.set("Bypass-Tunnel-Reminder", "none");

//       return headers;
//     },
//   }),
//   endpoints: (builder) => ({
//     getProducts: builder.query<{products:Product[],count:number}, void>({
//       query: () => "products",
//     }),
//   }),
// });


export const { useGetProductsQuery } = productApi;

import React from 'react';
import ReactDOM from 'react-dom';
import "./scss/main.scss";

import App from './App';
import { store } from './store/index'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'
import {
  persistStore,
} from 'redux-persist'


let persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
 <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);


import React, { useContext, useState, createContext } from 'react';

// Context is used in react to pass down data from a component to all of its children at all levels
const formContext = createContext({});
const formUpdateContext = createContext((name:string,value:string)=>{});

export function useForm(){
  return useContext(formContext)
}
export function useFormUpdate(){
  return useContext(formUpdateContext)
}

export function FormUpdateProvider({ children }:{children:React.ReactNode}) {
  const [value, setValue] = useState({name:'',value:''});

  const setUpdatedValue = (name:string,value:string) => {
    setValue({name,value})
  };

  /* ThemeContext.Provider will wrap all the component that we want to pass down some data to, its like global state for all the children of ThemeContext.provider (ThemeContext can be any name here we have created a context with that name so we are using that but we can name it in any way) */
  // This value prop inside will include state we want all the component inside provider to access
  return (
    <formContext.Provider value={value}>
      <formUpdateContext.Provider value={setUpdatedValue}>
        {children}
      </formUpdateContext.Provider>
    </formContext.Provider>
  );
}

import LazyLoad from "react-lazyload";
import classes from "./Item.module.scss";
import { BsCart4 } from "react-icons/bs";

import { Button, ButtonGroup } from "react-bootstrap";
import ItemCounter from "../itemCounter/ItemCounter";
import React, { useState } from "react";

import { changeCart } from "@/store/slices/Cart";
import { useDispatch } from "react-redux";

import { Product } from "@/types/commonTypes";

interface ItemProps {
  item: Product;
  itemStyles?: React.CSSProperties;
  itemClasses?: string;
}

const Item = ({ item, itemStyles, itemClasses }: ItemProps) => {
  const [quantity,setQuantity] = useState(1)
  const dispatch = useDispatch();
  const addItemToCart = () => {
    dispatch(changeCart({ item, quantity }));
  };

  return (
    <div
      className={`${classes.item} h-100 d-flex flex-column mx-auto ${itemClasses}`}
      style={itemStyles}
    >
      <LazyLoad height={200}>
        <img className={`${classes['item__img']} img-fluid`} src={item.image} alt={item.name} />
      </LazyLoad>
      <div className="p-3">
        <h3 className="fw-bold mb-3">{item.name}</h3>
      { false && <p>${item.price} / Kg</p>}

        <p>{item.description}</p>
      </div>
      <ButtonGroup aria-label="Basic example" className="d-flex p-3 mt-auto">
        <Button
          variant="primary"
          className="w-100 d-flex justify-content-center  bg-primary text-white align-items-center p-2"
          onClick={addItemToCart}
        >
       
            <BsCart4 size={15} />
            <p className="fw-bold text-capitalize mb-0  ms-2">Add to cart</p>
        
        </Button>
        <ItemCounter setCountExternal={setQuantity} initialCount={quantity}/>
      </ButtonGroup>
    </div>
  );
};

export default Item;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// initialize an empty api service that we'll inject endpoints into later as needed
export const citiesApi = createApi({
  reducerPath: "cities",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://countriesnow.space/api/v0.1/countries/state/cities",
  }),

  endpoints: (builder) => ({
    getCities: builder.mutation<
      {
        value: string;
        label: string;
      }[],
      { country?: string; state: string }
    >({
      query: ({ country = "United States", state }) => ({
        url: ``,
        method: "POST",
        body: {
          country,
          state,
        },
      }),
      transformResponse: (response: {
        data: string[];
        err: boolean;
        msg: string;
      }) => response.data.map((city) => ({ value: city, label: city })),
    }),
  }),
});
export const { useGetCitiesMutation } = citiesApi;

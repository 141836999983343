import classes from "./SectionHeading.module.scss";
const SectionHeading = ({ heading,color }: { heading: string; color?: string }) => {
  return (
    <h1
      className={`${classes["section-heading"]} text-center fw-bold mb-6 text-capitalize`}
      style={{ color: color && color }}
    >
      {heading}
    </h1>
  );
};

export default SectionHeading;

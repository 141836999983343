import { NavLink } from "react-router-dom";
import { BsCart4 } from "react-icons/bs";

import classes from "./Cart.module.scss";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/index";

const Cart = () => {
  const itemsCount = useSelector((state: RootState) => state.cart.items.length);

  let itemsCountJSX: React.ReactNode | null = (
    <span
      className={
        "rounded d-block position-absolute text-center d-block bg-primary " +
        classes["cart__items-count"]
      }
    >
      {itemsCount}
    </span>
  );

  if (!itemsCount) itemsCountJSX = null;

  return (
    <NavLink
      to="/cart"
      className="text-white align-self-center position-relative"
    >
      <BsCart4 className={`${classes["cart__icon"]}`} size={50} />
      {itemsCountJSX}
    </NavLink>
  );
};

export default Cart;

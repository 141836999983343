
import React from 'react'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Contact = ({style}:{style?:React.CSSProperties}) => {
    return (
        <ul style={style}>
        <li className="mb-3 d-flex">
          <FaPhoneAlt className="text-primary" size={20}/>
          <div className="ms-3 ">
            <p className="mb-0">+00 123 45678</p>
            <p className="mb-0">+00 123 45678</p>
          </div>
        </li>

        <li className="mb-4 d-flex">
          <FaEnvelope className="text-primary" size={20}  />
          <p className="ms-3 mb-0">Loremipsum@gmail.com</p>
        </li>
        <li className="d-flex">
          <FaMapMarkerAlt className="text-primary" size={20}  />
          <p className="ms-3 mb-0">
            Lorem ipsum dolor sit amet, <br/> consetetur sadipscing elitr, <br/> sed
            diam nonumy eirmod
          </p>
        </li>
      </ul>
    )
}

export default Contact


// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBase } from "./Base";

import { OrderForm } from "@/types/commonTypes";

export const orderApi = apiBase("order").injectEndpoints({
  endpoints: (builder) => ({
    postOrder: builder.mutation<
      { order: object; payment?: {url:string} },
      { userData: OrderForm; products: { id: number; quantity: number }[],paymentMethod?:string }
    >({
      query: ({ userData, products,paymentMethod = 'cod' }) => ({
        url: `order/create?paymentMethod=${paymentMethod}`,
        method: "POST",
        body: {
          userData,
          products,
        },
      }),
    }),
  }),
});

// export const productApi = createApi({
//   reducerPath: "products",
//   baseQuery: fetchBaseQuery({
//     baseUrl: "http://1754-182-182-103-9.ngrok.io/",
//     prepareHeaders: (headers, { getState }) => {
//       headers.set("Bypass-Tunnel-Reminder", "none");

//       return headers;
//     },
//   }),
//   endpoints: (builder) => ({
//     getProducts: builder.query<{products:Product[],count:number}, void>({
//       query: () => "products",
//     }),
//   }),
// });

export const { usePostOrderMutation } = orderApi;

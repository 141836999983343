import LazyLoad from "react-lazyload";

import {Button} from 'react-bootstrap'

import classes from './DoubleImgSection.module.scss'
interface DoubleImgSectionProps {
  img1: string;
  img2: string;
  text: string;
}

const DoubleImgSection = ({ img1, img2, text }: DoubleImgSectionProps) => {
  return (
    <div className={`text-white`}>
      <LazyLoad height={500}>
        <div
          className={`w-100 d-flex flex-column justify-content-center align-items-center text-center position-relative ${classes['double-img__first']} py-5 py-lg-0`}
          style={{
            background: `url(${img1}) center top/cover no-repeat`,
          }}
        >
            <p className="fw-3">{text}</p>
            <Button className="text-white d-block px-6 py-3 position-absolute" size="lg">Shop Now</Button>
        </div>
        <div
          className={`w-100 ${classes['double-img__second']} d-none d-lg-block` }
          style={{
            background: `url(${img2}) center top/cover no-repeat`,
            height: "50vh",
          }}
        ></div>
      </LazyLoad>
    </div>
  );
};

export default DoubleImgSection;

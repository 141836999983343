import React, { Suspense } from "react";
import LazyLoad from "react-lazyload";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import routes from "./routes/index";

import underconstructionImg from "./assets/images/General/under-development.jpg";
import { FormUpdateProvider } from "./contexts/FormContext";
import { Spinner } from "react-bootstrap";

import { useSelector } from "react-redux";
import { RootState } from "./store/index";

const PageUnderConstruction = () => (
  <div
    className="d-flex justify-content-center align-items-center flex-column px-2"
    style={{
      width: "100%",
      height: "100vh",
    }}
  >
    <h3 className="text-center text-primary fw-bold">Page Under Development</h3>
    <LazyLoad>
      <img
        className="img-fluid"
        src={underconstructionImg}
        alt="Under development"
      />
    </LazyLoad>
  </div>
);

const App: React.FC = () => {
  const cartItems = useSelector((state: RootState) => state.cart.items);
  return (
    <div className="App">
      <Router>
        <Routes>
          {routes.map((route, index) => {
            let element = (
              <Suspense
                fallback={
                  <div className="absolute-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                }
              >
                <route.layout>
                  <route.component></route.component>
                </route.layout>
              </Suspense>
            );
            if ((route.path === "/cart"  || route.path === '/checkout') && cartItems.length < 1)
              element = <Navigate replace to="/" />;

            if (route.path === "/checkout") {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<FormUpdateProvider>{element}</FormUpdateProvider>}
                />
              );
            }
            return <Route key={index} path={route.path} element={element} />;
          })}
          <Route path="*" element={<PageUnderConstruction />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;


import { NavLink } from "react-router-dom";
import classes from "./HeroSection.module.scss";

const HeroSection = ({
  img,
  heading,
}: {
  img: string;
  heading: [string, string];
}) => {
  return (
    <section
      className={`h-100vh d-flex justify-content-center align-items-center flex-column text-white section-margin ${classes["hero-section"]}`}
      style={{ background: `url(${img}) center top/cover no-repeat` }}
    >
      <h1
        className={`text-uppercase fw-bold font-perpetua ${classes["hero-section__lamak-foods"]} d-flex d-sm-block flex-column`}
      >
        <span>{heading[0]}</span>
        <span className="ms-3 text-primary">{heading[1]}</span>
      </h1>
      <p
        className={`text-capitalize text-center px-3 fw-light ${classes["hero-section__subheading"]}`}
      >
        Your one stop place for fresh meat
      </p>
      <div className="mt-sm-6 mt-4 d-flex">
        <NavLink
          to="/products"
          className={`btn-custom-outline btn-custom-outline-white ${classes["hero-section__btn"]}`}
        >
          <span>Shop now</span>
        </NavLink>
      </div>
    </section>
  );
};

export default HeroSection;

import SectionHeading from "../../general/SectionHeading/SectionHeading";
import classes from "./OrderProcess.module.scss";
import { Container } from "react-bootstrap";
import LazyLoad from "react-lazyload";
const steps = [
  {
    heading: "Add To Cart",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore",
    img: require("../../../assets/images/home/order-process-1.svg").default,
  },
  {
    heading: "Checkout",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore",
    img: require("../../../assets/images/home/order-process-2.svg").default,
  },
  {
    heading: "Payments",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore",
    img: require("../../../assets/images/home/order-process-3.svg").default,
  },
  {
    heading: "Shipping",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore",
    img: require("../../../assets/images/home/order-process-4.svg").default,
  },
];
const OrderProcess = () => {
  return (
    <section
      className={`${classes["order-process"]} pt-6 section-margin text-white text-center pb-5 pb-lg-0`}
    >
      <SectionHeading heading="How To Order From Us" color="white" />
      <Container>
        <div className="d-flex justify-content-lg-between flex-wrap flex-lg-nowrap">
          {steps.map((step, i) => (
            <div key={step.heading} className={`${classes["order-process__single"]}`}>
              <LazyLoad height={200}>
                <div
                  className={`${classes["order-process__icon-container"]} ${
                    i < steps.length - 1 ? classes.next : ""
                  } ${i=== 1 ? classes['next-lg'] : ''} d-flex justify-content-center align-items-center mx-auto`}
                >
                  <img
                    src={step.img}
                    alt={step.heading}
                  />
                </div>
              </LazyLoad>
              <h3
                className={`${classes["order-process__heading"]} fw-bold mt-4 mb-3`}
              >
                {step.heading}
              </h3>
              <p className={`${classes["order-process__text"]} mx-auto`}>
                {step.text}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default OrderProcess;

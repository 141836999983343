import {
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { Product } from "@/types/commonTypes";

interface Item extends Product {
  quantity: number;
}

export interface CartState {
  items: Item[];
  shippingFee:number
}

const initialState: CartState = {
  items: [],
  shippingFee:5
};

export const counterSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    changeCart: (
      state,
      action: PayloadAction<{ item: Product; quantity: number; type?: string }>
    ) => {
      const { item, quantity, type = "inc" } = action.payload;
      const itemIdx = state.items.findIndex((_item) => _item.id === item.id);

      if (itemIdx >= 0) {
        if (type === "inc") {
          state.items[itemIdx] = {
            ...state.items[itemIdx],
            quantity: state.items[itemIdx].quantity + quantity,
            subtotal: (state.items[itemIdx].quantity + quantity) * item.price,
          };
          return;
        }
        state.items[itemIdx] = {
          ...state.items[itemIdx],
          quantity: state.items[itemIdx].quantity - quantity,
          subtotal: (state.items[itemIdx].quantity - quantity) * item.price,
        };
        return;
      }
      state.items.push({
        ...action.payload.item,
        quantity,
        subtotal: quantity * item.price,
      });
    },
    removeFromCart(state, action: PayloadAction<{ itemId: number }>) {
      state.items = state.items.filter(
        (item) => item.id !== action.payload.itemId
      );
    },
    emptyCart(state) {
      state.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCart, removeFromCart, emptyCart } = counterSlice.actions;

export const memoizedCartTotal = createSelector(
  (state: any) => state.cart.items,
  (items) =>
    items.reduce(
      (prevTotal:number, currentItem:Item) =>
        (currentItem.price * currentItem.quantity )+ prevTotal,0
    )
);

export default counterSlice.reducer;




import DoubleImgSection from "../../general/DoubleImgSection/DoubleImgSection";
import classes from './WhyLamak.module.scss'
const DoubleImgSections = [
  {
    img1: require("../../../assets/images/home/why1.jpg").default,
    img2: require("../../../assets/images/home/why2.jpg").default,
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
  },
  {
    img1: require("../../../assets/images/home/why3.jpg").default,
    img2: require("../../../assets/images/home/why4.jpg").default,
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
  },
];

const WhyLamak = () => {
  return (
    <section className={`section-margin h-100vh d-flex flex-column flex-lg-row ${classes['why-lamak']}`}>
      {DoubleImgSections.map((section) => (
        <DoubleImgSection {...section} />
      ))}
    </section>
  );
};

export default WhyLamak;
